import { switchPath, apiVersion } from './config';

/**FUNCIONES PARA MANEJO DEL MODULO ESTUDIANTE QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getStudentExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/student-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'student.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postStudentImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/student-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addStudentApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-student`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        }) 
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getStudentsApi(token, state, limit, page, filter, filters) {
    let url = "";
    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    // console.log(options);
    //VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter!==null){
        url=`${switchPath()}/${apiVersion}/students?state=${state}&limit=${limit}&page=${page}&filter=${filter}&${options}`;
    }else{
        url=`${switchPath()}/${apiVersion}/students?state=${state}&limit=${limit}&page=${page}`;
    }
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getExportStudentsApi(token, state, filter, filters) {
    let url = "";
    //console.log(studentSearch);
    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    // console.log(options);
    //VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter!==undefined || filters !==null){
        url=`${switchPath()}/${apiVersion}/export-students?state=${state}&filter=${filter}&${options}`;
    }else{
        url=`${switchPath()}/${apiVersion}/export-students?state=${state}`;
    }
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };


    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        /*
        .then(lista, response => {
            response.students.map((item) => {
                lista.push(item);
              });
            return lista;
        })*/
        .catch(err => {
            return err.message;
        });
}

export async function getStudentsActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/students-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return await fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export async function getStudentsHasCoursesApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/students-hascourses?hasCourses=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return await fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateStudentApi(token, studentData, studentId) {
    const url = `${switchPath()}/${apiVersion}/update-student/${studentId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getStudentApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/student/${studentId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsByAdviserApi(token, adviserId) {
    const url = `${switchPath()}/${apiVersion}/students-adviser/${adviserId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsFinalConditionsByAdviserApi(token, adviserId) {
    const url = `${switchPath()}/${apiVersion}/students-finalconditions-adviser/${adviserId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}



export function getStudentsBySpecialtyApi(token, specialtyId) {
    const url = `${switchPath()}/${apiVersion}/students-specialty/${specialtyId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsByDocTypeApi(token, docTypeId) {
    const url = `${switchPath()}/${apiVersion}/students-doctype/${docTypeId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsByEntryConditionApi(token, entryConditionId) {
    const url = `${switchPath()}/${apiVersion}/students-entrycondition/${entryConditionId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsByFinalConditionApi(token, finalConditionId) {
    const url = `${switchPath()}/${apiVersion}/students-finalcondition/${finalConditionId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsByStudentConditionApi(token, studentConditionId) {
    const url = `${switchPath()}/${apiVersion}/students-studentcondition/${studentConditionId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStudentsBySchoolYearCycleApi(token, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/students-schoolyear-cycle/${schoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function activateStudentApi(token, studentId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-student/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function hasCoursesStudentApi(token, studentId, status) {
    const url = `${switchPath()}/${apiVersion}/hascourses-student/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            hasCourses: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteStudentApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/delete-student/${studentId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function insertCycleStudentApi(token, cycleData, studentId) {
    const url = `${switchPath()}/${apiVersion}/insert-cycle-student/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(cycleData)
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteCycleStudentApi(token, cycleData, studentId) {
    const url = `${switchPath()}/${apiVersion}/delete-cycle-student/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(cycleData)
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function insertYearAbandonmentApi(token, yearAbandonmentData, studentId) {
    const url = `${switchPath()}/${apiVersion}/insert-year-abandonment/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(yearAbandonmentData)
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteYearAbandonmentApi(token, yearAbandonmentData, studentId) {
    const url = `${switchPath()}/${apiVersion}/delete-year-abandonment/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(yearAbandonmentData)
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateOwnHoursByStudentIdApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/update-ownHours-student/${studentId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateFinalConditionsByStudentIdApi(token, studentId, finalConditionId, data) {
    const url = `${switchPath()}/${apiVersion}/update-finalconditions-student/${studentId}/${finalConditionId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}