import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Divider,
  Modal as ModalAntd,
  Popover,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import { DeleteOutlined, EyeFilled } from "@ant-design/icons";
//import Modal from "../../../Modal";
import {
  getStudentApi,
  insertCycleStudentApi,
  deleteCycleStudentApi,
  updateStudentApi,
  updateFinalConditionsByStudentIdApi,
  insertYearAbandonmentApi,
} from "../../../../api/student";
import { getAccessToken } from "../../../../api/auth";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getStudentConditionsActiveApi } from "../../../../api/studentCondition";
import { formatDateHour } from "../../../../utils/formatDate";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

import "./AddEditStudentCyclesForm.scss";
import { getFinalConditionsActiveApi } from "../../../../api/finalCondition";

const { confirm } = ModalAntd;

export default function AddEditStudentCycleForm(props) {
  const { student, setIsVisibleModal, setReloadStudents } = props;
  const [studentData, setStudentData] = useState([]);
  const [reload, setReload] = useState(false);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [listStudentConditionsActive, setListStudentConditionsActive] =
    useState([]);
  const [listFinalConditionsActive, setListFinalConditionsActive] = useState(
    []
  );

  const accessToken = getAccessToken();

  useEffect(() => {
    getStudentApi(accessToken, student._id).then((response) => {
      setStudentData(response.student);
    });
    setReload(false);
  }, [student, reload]);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);

  useEffect(() => {
    getStudentConditionsActiveApi(accessToken, true).then((response) => {
      setListStudentConditionsActive(response.studentConditions);
    });
  }, []);

  useEffect(() => {
    getFinalConditionsActiveApi(accessToken, true).then((response) => {
      setListFinalConditionsActive(response.finalConditions);
    });
  }, []);

  const [inputs, setInputs] = useState({
    schoolYear: "",
    studentCondition: "",
  });

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.schoolYear || !inputs.studentCondition) {
      notification["error"]({
        message: "Obligatorio: Ciclo Lectivo y Condición.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  // Verificar que no exista ciclo con el mismo año seleccionado
  const uniqueCycle = (cycle) => {
    let result = studentData.cycles.filter(
      (element) => element.schoolYear._id === cycle.schoolYear
    );
    if (result && result.length > 0) {
      return false;
    }
    return true;
  };

  const addCycleStudent = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      //verificar que el año seleccionado sea mayor al del año de admision
      let yearSelected = listSchoolYearsActive.find(
        (year) => year._id === inputs.schoolYear
      );
      let verify3 = studentData.yearAdmission
        ? studentData.yearAdmission <= yearSelected.year
        : true;
      if (verify3) {
        //verifico que el ciclo no se repita
        if (uniqueCycle(inputs)) {
          // let cycleData={...inputs};

          await insertCycleStudentApi(accessToken, inputs, student._id)
            .then((response) => {
              if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
              } else {
                notification["success"]({
                  message: "Ciclo guardado",
                });
                //setIsVisibleModal(false);
                return true;
              }
              return false;
              //setReloadStudents(true);
            })
            .then(async (result) => {
              if (result) {
                let responseStudent = await getStudentApi(
                  accessToken,
                  student._id
                );
                let studentUpdate = responseStudent.student;
                let verify = await verifyUpdateFinalConditions(studentUpdate);
                //En el caso que se haya modificado
                if (verify) {
                  const data={
                    finalConditions: studentUpdate.finalConditions,
                    yearsAbandonment:studentUpdate.yearsAbandonment
                  }
                  const finalConditionNew =
                    typeof studentUpdate.finalCondition === "object"
                      ? studentUpdate.finalCondition._id
                      : studentUpdate.finalCondition;
                  let resp = await updateFinalConditionsByStudentIdApi(
                    accessToken,
                    studentUpdate._id,
                    finalConditionNew,
                    data
                  );
                  if (
                    resp === "ERR_CONNECTION_REFUSED" ||
                    resp === "Failed to fetch" ||
                    resp === undefined
                  ) {
                    notification["error"]({
                      message: "Servidor caido",
                    });
                  } else if (resp.code !== 200) {
                    notification["error"]({
                      message: resp.message,
                    });
                  } else {
                    notification["success"]({
                      message: "Condicion final del estudiante modificado",
                    });
                    setIsVisibleModal(false);
                  }
                  setReloadStudents(true);
                } else {
                  setReloadStudents(true);
                  setIsVisibleModal(false);
                }
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        } else {
          notification["error"]({
            message: "El ciclo lectivo ya se encuentra registrado",
          });
        }
      } else {
        notification["error"]({
          message:
            "El año seleccionado es menor al año de admision del estudiante " +
            studentData.yearAdmission,
        });
      }
    }
  };

  const verifyUpdateFinalConditions = async (studentUpdate) => {
    let finalConditionNew = listFinalConditionsActive.find((condition) =>
      condition.description.toLowerCase().includes("en curso")
    );
    let conditionAbandonment = listFinalConditionsActive.find((condition) =>
      condition.description.toLowerCase().includes("abandonó")
    );
    let yearSelected = listSchoolYearsActive.find(
      (year) => year._id === inputs.schoolYear
    );

    //verificar si tiene cargados datos en el array el finalConditions
    //Caso 1: El array de condiciones finales existe y tiene datos
    if (
      studentUpdate.finalConditions &&
      studentUpdate.finalCondition !== null &&
      studentUpdate.finalConditions &&
      studentUpdate.finalConditions.length > 0
    ) {
      //Obtener ultima condicion cargada la que no tiene condicion final
      let oldFinalCondition = studentUpdate.finalConditions.findIndex(
        (i) => !i.finalDate
      );
      //verificar que la final condicion actual sea distinto a la condicion final en curso
      let verify =
        studentUpdate.finalConditions[oldFinalCondition].finalCondition !==
        finalConditionNew._id
          ? true
          : false;
      //verifico solo para el año actual y el año proximo
      let verify2 =
        new Date().getFullYear() === yearSelected.year - 1 ||
        new Date().getFullYear() === yearSelected.year
          ? true
          : false;
      // o verifico que el año de la final condicion anterior sea menor o igual al del ciclo actual
      let verify4 =
        new Date(
          studentUpdate.finalConditions[oldFinalCondition].initDate
        ).getFullYear() <= yearSelected.year
          ? true
          : false;
      //Casi 1 A: La condicion final es distinta a en curso 
      if (verify && (verify2 || verify4) ) {
        let newData = {
          finalCondition: finalConditionNew._id,
        };
        //En caso de que el año actual sea igual al seleccionado
        if (new Date().getFullYear() === yearSelected.year) {
          newData.initDate = formatDateHour(moment());
          //Actualizo ultima condicion con el valor finalDate
          studentUpdate.finalConditions[oldFinalCondition].finalDate =
            newData.initDate;
          studentUpdate.finalCondition = finalConditionNew._id;
        } else {
          //Agrego con el año seleccionado la fecha inicial si el año es igual al seleccionado
          if (
            new Date(
              studentUpdate.finalConditions[oldFinalCondition].initDate
            ).getFullYear() === yearSelected.year
          ) {
            //newData.initDate = formatDateHour(moment());
            let year = yearSelected.year;
            let dateNew = formatDateHour([year, 1, 2]);
            newData.initDate = dateNew;
            //Si el año de la fecha de la ultima condicion final es menor o igual a la nueva condicion
            if(new Date(
              studentUpdate.finalConditions[oldFinalCondition].initDate
            ) <= new Date(newData.initDate))
            {
              //agrego como fecha final el de la nueva condicion y asigno la nueva condicion 
              studentUpdate.finalConditions[oldFinalCondition].finalDate =
              newData.initDate;
              studentUpdate.finalCondition = finalConditionNew._id;
            }else{
              //sino le agrego como fecha final a la nueva condicion el inicio de la ultima 
              newData.finalDate = new Date(
                studentUpdate.finalConditions[oldFinalCondition].initDate
              );
            }
          } 
           //Si es el año diferente 
          else {
            //newData.initDate = formatDateHour(moment());
            let year = yearSelected.year;
            let dateNew = formatDateHour([year, 1, 2]);
            newData.initDate = dateNew;
            //Actualizo ultima condicion con el valor finalDate
            studentUpdate.finalConditions[oldFinalCondition].finalDate =
              newData.initDate;
            studentUpdate.finalCondition = finalConditionNew._id;
          }
        }
        //Si tiene consejero lo asigno
        if (studentUpdate.adviser) {
          newData.adviser = studentUpdate.adviser._id;
        }

        //Verifico que si es abandono este guardado en el array de años de abandono correspondiente
        if(studentUpdate.finalConditions[oldFinalCondition].finalCondition === conditionAbandonment._id){
          if (!studentUpdate.yearsAbandonment.some(item => item.schoolYear? item.schoolYear.year === new Date(studentUpdate.finalConditions[oldFinalCondition].initDate).getFullYear() : false)){
            let yearNew = listSchoolYearsActive.find(
              (year) => year.year === new Date(studentUpdate.finalConditions[oldFinalCondition].initDate).getFullYear() 
            );
            if(yearNew!==undefined){
              studentUpdate.yearsAbandonment.push({schoolYear:yearNew._id});
            }

          }
        }

        //Guardo la nueva condicion final en el array y la asigno a la condicion final actual       
        studentUpdate.finalConditions.push(newData);
        return true;
      }
      //independiente de la condicion y  si el año de la final condicion anterior sea mayor al del año seleccionado
      else if ((verify || !verify) && (!verify2 || !verify4) ) {
        let cicloExiste = false;
        for (let i = 0; i < studentUpdate.finalConditions.length; i++) {
          let ciclo = studentUpdate.finalConditions[i];

          //extraemos los años de inicio y final de cada ciclo existente
          let cicloAnioInicio = new Date(ciclo.initDate).getFullYear();
          let cicloAnioFinal = ciclo.finalDate
            ? new Date(ciclo.finalDate).getFullYear()
            : new Date().getFullYear();

          // Si el ciclo es "Cursando" y se solapan los años, no lo agregamos
          if (
            //ciclo.finalCondition === finalConditionNew._id &&
            // Verifica si el ciclo actual se solapa con el ciclo nuevo
            ((yearSelected.year >= cicloAnioInicio &&
              yearSelected.year <= cicloAnioFinal) ||
              (yearSelected.year >= cicloAnioInicio &&
                yearSelected.year <= cicloAnioFinal) ||
              (yearSelected.year <= cicloAnioInicio &&
                yearSelected.year >= cicloAnioFinal))
          ) {
            cicloExiste = true;
            break; // Si ya existe un ciclo en el mismo rango, no lo agregamos
          }
        }

        // Si no existe un ciclo "Cursando" en el mismo año, lo agregamos
        if (!cicloExiste) {
          let newData2 = {
            finalCondition: finalConditionNew._id,
            initDate: formatDateHour([yearSelected.year, 1, 2]),
            finalDate: formatDateHour([yearSelected.year, 12, 31]),
          };
          studentUpdate.finalConditions.push(newData2);

          return true;
        } else {
          return false;
        }
      }
    }
    //Caso 2 : no exista condicion final o es null y el array esta vacio o no existe
    else if (
      (!studentUpdate.finalCondition || studentUpdate.finalCondition == null) &&
      (!studentUpdate.finalConditions ||
        (studentUpdate.finalConditions &&
          studentUpdate.finalConditions.length === 0))
    ) {
      let newData = {
        finalCondition: finalConditionNew._id,
      };
      //En caso de que el Año de Admision sea un año proximo (mayor al actual)
      if (new Date().getFullYear() === yearSelected.year) {
        newData.initDate = formatDateHour(moment());
      } else {
        let year = yearSelected.year;
        let dateNew = formatDateHour([year, 1, 2]);
        newData.initDate = dateNew;
      }
      //Si tiene consejero lo asigno
      if (studentUpdate.adviser) {
        newData.adviser = studentUpdate.adviser._id;
      }
      //Guardo la nueva condicion final en el array y la asigno a la condicion final actual
      studentUpdate.finalConditions.push(newData);
      studentUpdate.finalCondition = finalConditionNew._id;
      return true;
    }

    return false;
  };

  return (
    <div className="add-studentcycles-form">
      <AddForm
        student={studentData}
        setReload={setReload}
        setReloadStudents={setReloadStudents}
        inputs={inputs}
        setInputs={setInputs}
        addCycleStudent={addCycleStudent}
        listSchoolYearsActive={listSchoolYearsActive}
        setListSchoolYearsActive={setListSchoolYearsActive}
        listStudentConditionsActive={listStudentConditionsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    student,
    setReload,
    setReloadStudents,
    inputs,
    setInputs,
    addCycleStudent,
    listStudentConditionsActive,
    listSchoolYearsActive,
  } = props;
  const { Option } = Select;

  const [viewCyclesStudent, setViewCyclesStudent] = useState(false);

  const viewCycles = () => {
    setViewCyclesStudent(!viewCyclesStudent);
  };

  return (
    <Form className="form-add" onSubmit={addCycleStudent}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>
            Ciclo Lectivo
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona un ciclo lectivo"
              value={inputs.schoolYear}
              onChange={(e) => setInputs({ ...inputs, schoolYear: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((year) => {
                    return <Option key={year._id}>{year.year}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>
            Condición del Estudiante
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona una condicion"
              value={inputs.studentCondition}
              onChange={(e) => setInputs({ ...inputs, studentCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentConditionsActive
                ? listStudentConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-add"
            onClick={addCycleStudent}
          >
            Guardar
          </Button>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-view"
            onClick={viewCycles}
            icon={<EyeFilled />}
          >
            Ver ciclos
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {viewCyclesStudent ? (
          student.cycles ? (
            <Col span={24}>
              <Cycles
                cycles={student.cycles}
                student={student}
                setReload={setReload}
                setReloadStudents={setReloadStudents}
              />
            </Col>
          ) : null
        ) : null}
      </Row>
    </Form>
  );
}

function Cycles(props) {
  const { cycles, setReload, setReloadStudents, student } = props; //showDeleteConfirm

  const showDeleteConfirm = (cycle) => {
    const accessToken = getAccessToken();
    //console.log("cycle: ", cycle);
    //console.log("student: ", student);

    confirm({
      title: "Eliminando -> Ciclo del Estudiante",
      content: `¿Estás seguro que quieres eliminar el ciclo ${
        cycle.schoolYear.year + "-" + cycle.studentCondition.description
      } del estudiante?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteCycleStudentApi(accessToken, cycle, student._id).then(
          (response) => {
            if (response.code === 200) {
              setReload(true);
              setReloadStudents(true);
            }
          }
        );
      },
    });
  };
  const delete_cycle = (
    <div>
      {" "}
      <p>Eliminar ciclo</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Ciclo",
      dataIndex: "schoolYear",
      sorted: (a, b) => b.schoolYear.year - a.schoolYear.year,
      render: (schoolYear) => (schoolYear ? schoolYear.year : null),
      width: "30%",
    },
    {
      title: "Condición",
      dataIndex: "studentCondition",
      render: (studentCondition) =>
        studentCondition ? studentCondition.description : null,
      width: "50%",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, student) => (
        <div>
          <>
            <Popover content={delete_cycle}>
              <Button
                className="button-inscription"
                type="danger"
                onClick={() => showDeleteConfirm(student)}
              >
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={{ pageSize: 6 }}
      dataSource={cycles}
      size="small"
      columns={columns}
      rowKey="_id"
    />
  );
}
