import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  notification,
  Divider,
  Popover,
  Table,
  Space,
  Modal as ModalAntd,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  TeamOutlined,
  TagOutlined,
  EyeFilled,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  updateStudentApi,
  insertYearAbandonmentApi,
  getStudentApi,
  deleteYearAbandonmentApi,
} from "../../../../api/student";
import { getAdvisersActiveApi } from "../../../../api/adviser";
import { getSpecialtiesActiveApi } from "../../../../api/specialty";
import { getEntryConditionsActiveApi } from "../../../../api/entryCondition";
import { getFinalConditionsActiveApi } from "../../../../api/finalCondition";
import { getDocTypesActiveApi } from "../../../../api/docType";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getAccessToken } from "../../../../api/auth";
import {
  emailValidation,
  numberValidation,
  phoneValidation,
  removeClassErrorSuccess,
} from "../../../../utils/formValidation";
import { handleYearsShow } from "../../../../utils/checkUserPermission";
//import { CITIES } from "../../../../utils/cities";
import { COUNTRIES } from "../../../../utils/countries";
import {
  formatDateHour,
  formatDateYear,
  formatDateCalendar,
  formatDateView,
  formatDateView2,
} from "../../../../utils/formatDate";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
// import { getStudentConditionsActiveApi } from "../../../../api/studentCondition";

import "./EditStudentForm.scss";

const { TextArea } = Input;
const { confirm } = ModalAntd;

export default function EditStudentForm(props) {
  const { student, setIsVisibleModal, setReloadStudents } = props;
  const [studentData, setStudentData] = useState({});
  const [studentData2, setStudentData2] = useState([]);
  const [reload, setReload] = useState(false);
  const [yearsAbandonmentData, setYearsAbandonmentData] = useState({});
  const [yearsAbandonmentData2, setYearsAbandonmentData2] = useState([]);
  const [advisersData, setAdvisersData] = useState([]);
  const [finalConditionsData, setFinalConditionsData] = useState([]);
  const [listAdvisersActive, setListAdvisersActive] = useState([]);
  const [listSpecialtiesActive, setListSpecialtiesActive] = useState([]);
  const [listEntryConditionsActive, setListEntryConditionsActive] = useState(
    []
  );
  const [listFinalConditionsActive, setListFinalConditionsActive] = useState(
    []
  );
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [listDocTypesActive, setListDocTypesActive] = useState([]);
  const [advisers, setAdvisers] = useState([]);
  // const [listStudentConditionsActive, setListStudentConditionsActive] = useState([]);

  const accessToken = getAccessToken();

  //trae los datos del estudiante
  useEffect(() => {
    setStudentData({
      _id: student._id,
      student_code: student.student_code,
      name: student.name,
      lastname: student.lastname,
      country: student.country,
      docType: student.docType ? student.docType._id : null,
      documentNumber: student.documentNumber,
      city: student.city,
      phone: student.phone,
      email: student.email,
      yearAdmission: student.yearAdmission
        ? formatDateYear(student.yearAdmission.toString())
        : null,
      yearGraduation: student.yearGraduation
        ? formatDateYear(student.yearGraduation.toString())
        : null,
      specialty: student.specialty ? student.specialty._id : null,
      entryCondition: student.entryCondition
        ? student.entryCondition._id
        : null,
      finalCondition: student.finalCondition
        ? student.finalCondition._id
        : null,
      // finalConditions: student.finalConditions ? student.finalConditions : null,
      descriptionFinalCondition: student.finalCondition
        ? student.finalCondition.description
          ? student.finalCondition.description
          : null
        : null,
      hoursCoursed: student.hoursCoursed,
      //yearsAbandonment: student.yearsAbandonment? student.yearsAbandonment: null, //LO QUITO PARA QUE NO LO ACTUALICE EN EL UPDATE CON EL INSERT DE AÑOS DE ABANDONO
      adviser: student.adviser ? student.adviser._id : null,
      // advisers: student.advisers ? student.advisers : null,
      observation: student.observation,
      cycles: student.cycles ? student.cycles : null,
    });
    setReload(false);
  }, [student]);

  //lo separo para manejarlo individualmente en el insertYearAbandonment y que no se actualice con el update
  //TODO: revisar si es necesario ya que ahora el año seleccionado se guarda en otra variable
  useEffect(() => {
    setYearsAbandonmentData({
      yearsAbandonment: student.yearsAbandonment
        ? student.yearsAbandonment
        : null,
    });
    // setReload(false);
  }, [student]);

  useEffect(() => {
    setAdvisers(
      student.advisers ? JSON.parse(JSON.stringify(student.advisers)) : []
    );
    setFinalConditionsData(
      student.finalConditions
        ? JSON.parse(JSON.stringify(student.finalConditions))
        : []
    );
    // setReload(false);
  }, [student]);

  // USO ESTE PARA uniqueYearAbandonment
  useEffect(() => {
    getStudentApi(accessToken, student._id).then((response) => {
      setStudentData2(response.student);
      setYearsAbandonmentData2(response.student.yearsAbandonment);
      setYearsAbandonmentData({
        ...yearsAbandonmentData,
        yearsAbandonment: response.student.yearsAbandonment
          ? response.student.yearsAbandonment
          : null,
      });
    });
    setReload(false);
  }, [student, reload]);

  //console.log("years2: ", yearsAbandonmentData2);

  useEffect(() => {
    getAdvisersActiveApi(accessToken, true).then((response) => {
      setListAdvisersActive(response.advisers);
    });
  }, []);

  useEffect(() => {
    getSpecialtiesActiveApi(accessToken, true).then((response) => {
      setListSpecialtiesActive(response.specialties);
    });
  }, []);

  useEffect(() => {
    getEntryConditionsActiveApi(accessToken, true).then((response) => {
      setListEntryConditionsActive(response.entryConditions);
    });
  }, []);

  useEffect(() => {
    getFinalConditionsActiveApi(accessToken, true).then((response) => {
      setListFinalConditionsActive(response.finalConditions);
    });
  }, []);

  useEffect(() => {
    getDocTypesActiveApi(accessToken, true).then((response) => {
      setListDocTypesActive(response.docTypes);
    });
  }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);

  // useEffect(() => {
  //   getStudentConditionsActiveApi(accessToken, true).then((response) => {
  //     setListStudentConditionsActive(response.studentConditions);
  //   });
  // }, []);

  const [formValid, setFormValid] = useState({});

  // TODO: verificar que no exista un año de abandono duplicado
  const uniqueYearAbandonment = (yearAbandonment) => {
    let result = studentData2.yearsAbandonment.filter(
      (element) => element.schoolYear._id === yearAbandonment.schoolYear
    );
    if (result && result.length > 0) {
      return false;
    }
    return true;
  };

  const inputValidation = (e) => {
    const { name } = e.target;

    removeClassErrorSuccess(e.target);

    setStudentData({
      ...studentData,
      [name]: e.target.value,
    });
    if (e.target.value !== "") {
      if (name === "email") {
        setFormValid({
          ...formValid,
          [name]: emailValidation(e.target),
        });
      }

      if (name === "phone") {
        setFormValid({
          ...formValid,
          [name]: phoneValidation(e.target),
        });
      }
      if (name === "documentNumber") {
        setFormValid({
          ...formValid,
          [name]: numberValidation(e.target),
        });
      }
    }
  };

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !studentData.name ||
      !studentData.lastname ||
      !studentData.documentNumber ||
      !studentData.phone ||
      !studentData.email
    ) {
      //BUG
      notification["error"]({
        message: "Obligatorios: *",
      });
      errorExists = true;
    } else if (formValid.phone === false) {
      //BUG
      notification["error"]({
        message: "Telefóno inválido",
      });
      errorExists = true;
    } else if (formValid.email === false && studentData.email !== "") {
      //BUG
      notification["error"]({
        message: "Email inválido",
      });
      errorExists = true;
    } else if (formValid.documentNumber === false) {
      //BUG
      notification["error"]({
        message: "DNI inválido",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const verifyUpdateFinalConditions = (studentUpdate) => {
    studentUpdate.finalConditions = [...finalConditionsData];
    if (studentUpdate.finalCondition) {
      let oldFinalCondition = studentUpdate.finalConditions.findIndex(
        (i) => !i.finalDate
      );
      //verificar que no sea la misma opcion seleccionada o se haya seleccionado otro consejero
      //En tal caso, que no exista la condicion final ultima (tambien se guardarian los datos)
      let verify = student.finalCondition
        ? studentUpdate.finalCondition !== student.finalCondition._id
          ? true
          : false
        : true;
      let verify2 =
        (student.adviser && studentUpdate.adviser !== student.adviser._id) ||
        (!student.adviser && studentUpdate.adviser)
          ? true
          : false;
      // let verify=finalConditionsData.filter(i=>i.finalCondition === studentUpdate.finalCondition
      //   && i.adviser === studentUpdate.adviser && i.schoolYear === yearSchool[0]._id);
      if ((verify || verify2)) {
        // studentUpdate.finalConditions=[...finalConditionsData];
        if (oldFinalCondition !== -1 ) {
          //En caso de que el Año de Admision sea un año proximo (mayor al actual)
          if (
            studentData.yearAdmission &&
            moment.isMoment(studentData.yearAdmission) &&
            new Date().getFullYear() < studentData.yearAdmission.year()
          ) {
            let year = studentData.yearAdmission.year();
            let dateNew = formatDateHour([year, 1, 1]);
            studentUpdate.finalConditions[oldFinalCondition].finalDate =
              dateNew;
          } else {
            studentUpdate.finalConditions[oldFinalCondition].finalDate =
              formatDateHour(moment());
          }
        }
        let newData = {
          finalCondition: studentUpdate.finalCondition,
        };
        //En caso de que el Año de Admision sea un año proximo (mayor al actual)
        if (
          studentUpdate.yearAdmission &&
          moment.isMoment(studentUpdate.yearAdmission) &&
          new Date().getFullYear() < studentUpdate.yearAdmission.year()
        ) {
          let year = studentUpdate.yearAdmission.year();
          let dateNew = formatDateHour([year, 1, 1]);
          newData.initDate = dateNew;
        } else {
          newData.initDate = formatDateHour(moment());
        }

        if (studentUpdate.adviser) {
          newData.adviser = studentUpdate.adviser;
        }
        studentUpdate.finalConditions.push(newData);
        return true;
      } else if (
        studentUpdate.finalCondition &&
        student.finalCondition &&
        studentUpdate.finalCondition === student.finalCondition._id 
      ) {
        if (oldFinalCondition === -1) {
          let newData = {
            finalCondition: studentUpdate.finalCondition,
            // schoolYear: yearSchool[0]._id,
            //initDate: formatDateHour(moment()),
          };
          //En caso de que el Año de Admision sea un año proximo (mayor al actual)
          if (
            studentUpdate.yearAdmission &&
            moment.isMoment(studentUpdate.yearAdmission) &&
            new Date().getFullYear() < studentUpdate.yearAdmission.year()
          ) {
            let year = studentUpdate.yearAdmission.year();
            let dateNew = formatDateHour([year, 1, 1]);
            newData.initDate = dateNew;
          } else {
            newData.initDate = formatDateHour(moment());
          }

          if (studentUpdate.adviser) {
            newData.adviser = studentUpdate.adviser;
          }
          studentUpdate.finalConditions.push(newData);
          return true;
        }
      }
    }
    return false;
    // console.log(verify);
  };

 
  const verifyUpdateAdvisers = (studentUpdate) => {
    //verificar que no sea el mismo consejero seleccionado (actual) antes de actualizar
    studentUpdate.advisers = [...advisers];
    if (
      (studentUpdate.adviser &&
        student.adviser &&
        student.adviser._id !== studentUpdate.adviser) ||
      (studentUpdate.adviser && !student.adviser)
    ) {
      //datos de nuevo adviser seleccionado
      // studentUpdate.advisers=[...advisers];
      let newAdviser = {
        adviser: studentUpdate.adviser,
        //initDate: formatDateHour(moment()),
      };
      //En caso de que el Año de Admision sea un año proximo (mayor al actual)
      if (
        studentUpdate.yearAdmission &&
        moment.isMoment(studentUpdate.yearAdmission) &&
        new Date().getFullYear() < studentUpdate.yearAdmission.year()
      ) {
        let year = studentUpdate.yearAdmission.year();
        let dateNew = formatDateHour([year, 1, 1]);
        newAdviser.initDate = dateNew;
      } else {
        newAdviser.initDate = formatDateHour(moment());
      }

      studentUpdate.advisers.push(newAdviser);
      if (student.adviser) {
        let oldAdviser = studentUpdate.advisers.findIndex(
          (i) => i.adviser._id === student.adviser._id && !i.finalDate
        );
        if (oldAdviser !== -1) {
          //En caso de que el Año de Admision sea un año proximo (mayor al actual)
          if (
            studentUpdate.yearAdmission &&
            moment.isMoment(studentUpdate.yearAdmission) &&
            new Date().getFullYear() < studentUpdate.yearAdmission.year()
          ) {
            let year = studentUpdate.yearAdmission.year();
            let dateNew = formatDateHour([year, 1, 1]);
            studentUpdate.advisers[oldAdviser].finalDate = dateNew;
          } else {
            studentUpdate.advisers[oldAdviser].finalDate = formatDateHour(
              moment()
            );
          }
        }
      }
      return true;
    } else if (
      studentUpdate.adviser &&
      student.adviser &&
      student.adviser._id === studentUpdate.adviser
    ) {
      let oldAdviser = studentUpdate.advisers.findIndex(
        (i) => i.adviser._id === student.adviser._id && !i.finalDate
      );
      if (oldAdviser === -1) {
        let newAdviser = {
          adviser: studentUpdate.adviser,
          //initDate: formatDateHour(moment()),
        };
        //En caso de que el Año de Admision sea un año proximo (mayor al actual)
        if (
          studentUpdate.yearAdmission &&
          moment.isMoment(studentUpdate.yearAdmission) &&
          new Date().getFullYear() < studentUpdate.yearAdmission.year()
        ) {
          let year = studentUpdate.yearAdmission.year();
          let dateNew = formatDateHour([year, 1, 1]);
          newAdviser.initDate = dateNew;
        } else {
          newAdviser.initDate = formatDateHour(moment());
        }

        studentUpdate.advisers.push(newAdviser);
      }
      return true;
    }
  };

  const updateStudent = async (e) => {
    e.preventDefault();
    const error = isFormValid();
    // console.log("error", error);
    //console.log(studentData.yearsAbandonment);
    // console.log(yearsAbandonmentData.yearsAbandonment);
    // console.log(studentData);
    if (
      !error &&
      yearsAbandonmentData.yearsAbandonment &&
      yearsAbandonmentData.yearsAbandonment !== null &&
      yearsAbandonmentData.yearsAbandonment !== undefined
    ) {
      //if (!error ) {
      // console.log(studentData);
      const token = getAccessToken();
      //if (studentData.descriptionFinalCondition === ("Recursante" || "recursante" || "recursando" || "recursa")) {
      if (studentData.descriptionFinalCondition === "Abandonó") {
        // let yearsAbandonmentData={...inputs};
        //Verifico que exista un año de abandono nuevo seleccionado
        if (
          yearsAbandonmentData.yearAbandonment &&
          yearsAbandonmentData.yearAbandonment !== null
        ) {
          //verifico que el año no se repita
          if (uniqueYearAbandonment(yearsAbandonmentData.yearAbandonment)) {
            let studentUpdate = studentData; //es let porque se va actualizando
            verifyUpdateFinalConditions(studentUpdate);
            verifyUpdateAdvisers(studentUpdate);
            await updateStudentApi(token, studentUpdate, student._id).then(
              (result) => {
                if (
                  result.message === "ERR_CONNECTION_REFUSED" ||
                  result.message === "Failed to fetch" ||
                  result.message === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                  setIsVisibleModal(true);
                } else if (result.code !== 200) {
                  notification["error"]({
                    message: result.message,
                  });
                  setIsVisibleModal(true);
                } else {
                  notification["success"]({
                    message: result.message, //el mensaje que viene del server
                  });
                  //console.log("respuesta: " + result.message);
                  // setIsVisibleModal(false);
                  setReloadStudents(true);
                }
              }
            );
            await insertYearAbandonmentApi(
              accessToken,
              yearsAbandonmentData.yearAbandonment,
              student._id
            )
              .then((response) => {
                if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else if (response.code !== 200) {
                  notification["error"]({
                    message: response.message,
                  });
                } else {
                  notification["success"]({
                    message: "Año de abandono agregado",
                  });
                  setIsVisibleModal(false);
                }
                setReloadStudents(true);
              })
              .catch((err) => {
                notification["error"]({
                  message: err,
                });
              });
          } else {
            notification["error"]({
              message: "El año de abandono ya se encuentra registrado",
            });
          }
        } else {
          let studentUpdate = studentData; //es let porque se va actualizando
          verifyUpdateFinalConditions(studentUpdate);
          verifyUpdateAdvisers(studentUpdate);
          await updateStudentApi(token, studentUpdate, student._id).then(
            (result) => {
              if (
                result.message === "ERR_CONNECTION_REFUSED" ||
                result.message === "Failed to fetch" ||
                result.message === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
                setIsVisibleModal(true);
              } else if (result.code !== 200) {
                notification["error"]({
                  message: result.message,
                });
                setIsVisibleModal(true);
              } else {
                notification["success"]({
                  message: result.message, //el mensaje que viene del server
                });
                //console.log("respuesta: " + result.message);
                setIsVisibleModal(false);
                setReloadStudents(true);
              }
            }
          );
        }
      } else {
        let studentUpdate = studentData; //es let porque se va actualizando
        verifyUpdateFinalConditions(studentUpdate);
        verifyUpdateAdvisers(studentUpdate);
        await updateStudentApi(token, studentUpdate, student._id).then(
          (result) => {
            if (
              result.message === "ERR_CONNECTION_REFUSED" ||
              result.message === "Failed to fetch" ||
              result.message === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setIsVisibleModal(true);
            } else if (result.code !== 200) {
              notification["error"]({
                message: result.message,
              });
              setIsVisibleModal(true);
            } else {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              //console.log("respuesta: " + result.message);
              setReloadStudents(true);
              setIsVisibleModal(false);
            }
          }
        );
      }
    }
  };

  return (
    <div className="edit-student-form">
      <EditForm
        studentData={studentData}
        studentData2={studentData2}
        setStudentData={setStudentData}
        yearsAbandonmentData={yearsAbandonmentData}
        advisers={advisers}
        setYearsAbandonmentData={setYearsAbandonmentData}
        setReload={setReload}
        setReloadStudents={setReloadStudents}
        updateStudent={updateStudent}
        listAdvisersActive={listAdvisersActive}
        listSpecialtiesActive={listSpecialtiesActive}
        listEntryConditionsActive={listEntryConditionsActive}
        listFinalConditionsActive={listFinalConditionsActive}
        listSchoolYearsActive={listSchoolYearsActive}
        listDocTypesActive={listDocTypesActive}
        // listStudentConditionsActive={listStudentConditionsActive}
        finalConditionsData={finalConditionsData}
        setFinalConditionsData={setFinalConditionsData}
        inputValidation={inputValidation}
        setAdvisers={setAdvisers}
        //reload={reload}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    studentData,
    setStudentData,
    studentData2,
    //reload,
    advisers,
    updateStudent,
    listAdvisersActive,
    listSpecialtiesActive,
    listEntryConditionsActive,
    listFinalConditionsActive,
    listSchoolYearsActive,
    listDocTypesActive,
    //descriptionFinalCondition,
    // listStudentConditionsActive,
    inputValidation,
    yearsAbandonmentData,
    setYearsAbandonmentData,
    setReload,
    setReloadStudents,
    finalConditionsData,
    setFinalConditionsData,
    setAdvisers,
  } = props;
  const { Option } = Select;
  var finalCondition2 = studentData.finalCondition;
  var descriptionFinalCondition2 = studentData.descriptionFinalCondition;

  const [viewYearsAbandonment, setViewYearsAbandonment] = useState(false);
  const [viewListAdvisers, setViewListAdvisers] = useState(false);
  const [viewListFinalConditions, setViewListFinalConditions] = useState(false);

  const viewYears = () => {
    setViewYearsAbandonment(!viewYearsAbandonment);
  };

  const viewAdvisers = () => {
    setViewListAdvisers(!viewListAdvisers);
  };

  const viewFinalConditions = () => {
    setViewListFinalConditions(!viewListFinalConditions);
  };

  //if(studentData.descriptionFinalCondition !== undefined){
  //  setNewValue(studentData.descriptionFinalCondition);
  //}

  //console.log(studentData.descriptionFinalCondition);

  //uso de onChange para finalCondition
  const handleChange = (defaultValue) => {
    //console.log(defaultValue);
    listFinalConditionsActive.map((i) => {
      if (i._id === defaultValue) {
        //console.log(i.description);
        //setStudentData({ ...studentData, finalCondition: i._id })
        descriptionFinalCondition2 = i.description;
        finalCondition2 = i._id;
        studentData.descriptionFinalCondition = descriptionFinalCondition2;
        setStudentData({ ...studentData, finalCondition: finalCondition2 });
        //setStudentData({ ...studentData, finalCondition: defaultValue.value })
      }
      return null; //retorno algo por los warningsS
    });
    //console.log(studentData.finalCondition);
    //console.log(finalCondition2);
  };

  /*
  const handleSelect = (defaultValue) => {
    console.log("defaultValue", defaultValue);
    //setStudentData({ ...studentData, descriptionFinalCondition: defaultValue.children }) undefined
    listFinalConditionsActive.map((i) => {
      if(i._id === defaultValue){
        console.log("i.description", i.description);
        setStudentData({ ...studentData, descriptionFinalCondition: i.description })
        descriptionFinalCondition2 = i.description;
        finalCondition2 = i._id;
      }
    //setStudentData({ ...studentData, finalCondition: e })
    })
    //setStudentData({ ...studentData, descriptionFinalCondition: defaultValue.children })
    console.log("studentData.descriptionFinalCondition", studentData.descriptionFinalCondition);
    console.log("descriptionFinalCondition2", descriptionFinalCondition2);
    console.log("finalCondition2", finalCondition2);
  }
  */

  return (
    <Form className="form-edit" onSubmit={updateStudent}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={studentData.student_code ? studentData.student_code : null}
              onChange={(e) =>
                setStudentData({ ...studentData, student_code: e })
              }
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Por favor ingrese un nombre",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="nombre"
              value={studentData.name}
              onChange={(e) =>
                setStudentData({ ...studentData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Apellido
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Apellido"
              value={studentData.lastname}
              onChange={(e) =>
                setStudentData({ ...studentData, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Tipo de documento</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona un tipo de documento"
              value={studentData.docType}
              onChange={(e) => setStudentData({ ...studentData, docType: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDocTypesActive
                ? listDocTypesActive.map((docType) => {
                    return (
                      <Option key={docType._id}>{docType.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Nº documento
          </label>
          <Form.Item>
            <Input
              // prefix={<EnvironmentOutlined />}
              placeholder="DNI"
              name={"documentNumber"}
              value={studentData.documentNumber}
              maxLength={20}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Ciudad</label>
          <Form.Item>
            {/* {(studentData.country!=="Argentina" && studentData.country!==undefined)? */}
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Ciudad"
              value={studentData.city}
              onChange={(e) =>
                setStudentData({ ...studentData, city: e.target.value })
              }
            />
            {/* :
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una ciudad"
              value={studentData.city}
              onChange={(e) => setStudentData({ ...studentData, city: e })}
              optionFilterProp="children"
              dropdownStyle={{ minWidth: "40%" }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {CITIES
                ? CITIES.localidades.map((city) => {
                    return (
                      <Option key={city.nombre}>
                        {city.provincia? city.municipio.nombre? city.nombre +"("+city.municipio.nombre +","+city.provincia.nombre+")" : city.nombre + "("+city.provincia.nombre +")" : city.nombre}
                      </Option>
                    );
                  })
                : null}
            </Select>
          } */}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">País</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona un país"
              value={studentData.country}
              // defaultValue={"Argentina"}
              onChange={(e) =>
                setStudentData({ ...studentData, country: e, city: null })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {COUNTRIES
                ? COUNTRIES.map((country) => {
                    return <Option key={country}>{country}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Teléfono
          </label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Teléfono"
              type="phone"
              name="phone"
              value={studentData.phone}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Email
          </label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={studentData.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Ciclo Lectivo</label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona un ciclo lectivo"
              value={studentData.schoolYear}
              onChange={(e) => setStudentData({ ...studentData, schoolYear: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((year) => {
                    return (
                      <Option key={year._id}>
                        {year.year}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Condición del Estudiante</label>
          <Form.Item>
          <Select
              showSearch
              placeholder="Selecciona una condicion"
              value={studentData.studentCondition}
              onChange={(e) => setStudentData({ ...studentData, studentCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentConditionsActive
                ? listStudentConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Especialidad</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una especialidad"
              value={studentData.specialty}
              onChange={(e) => setStudentData({ ...studentData, specialty: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSpecialtiesActive
                ? listSpecialtiesActive.map((specialty) => {
                    return (
                      <Option key={specialty._id}>
                        {specialty.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Año ingreso</label>
          <Form.Item>
            <DatePicker
              // prefix={<CalendarOutlined/>}
              type="date"
              placeholder="Año de de ingreso"
              value={studentData.yearAdmission}
              format="yyyy"
              picker="year"
              onChange={(e) =>
                setStudentData({ ...studentData, yearAdmission: e })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Año egreso</label>
          <Form.Item>
            <DatePicker
              // prefix={<CalendarOutlined/>}
              type="date"
              placeholder="Año de graduación"
              value={studentData.yearGraduation}
              picker="year"
              onChange={(e) =>
                setStudentData({ ...studentData, yearGraduation: e })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Consejero</label>
          <Form.Item>
            <Select
              prefix={<TeamOutlined />}
              showSearch
              placeholder="Selecciona un consejero"
              value={studentData.adviser}
              onChange={(e) => setStudentData({ ...studentData, adviser: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listAdvisersActive
                ? listAdvisersActive.map((advisers) => {
                    return (
                      <Option key={advisers._id}>
                        {advisers.name + " " + advisers.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Condición de Ingreso
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una condición de ingreso"
              value={studentData.entryCondition}
              onChange={(e) =>
                setStudentData({ ...studentData, entryCondition: e })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listEntryConditionsActive
                ? listEntryConditionsActive.map((entryCondition) => {
                    return (
                      <Option key={entryCondition._id}>
                        {entryCondition.description
                          ? entryCondition.description
                          : ""}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Condición final</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una condición final"
              value={studentData.finalCondition}
              defaultValue={studentData.finalCondition}
              defaultActiveFirstOption={true}
              //onChange = {(defaultValue) => setStudentData({ ...studentData, finalCondition: defaultValue }) }
              //onChange={(defaultValue) => setStudentData({ ...studentData, descriptionFinalCondition: defaultValue.children }, { ...studentData, finalCondition: defaultValue })}
              onChange={handleChange}
              //onSelect = {handleSelect}
              //onSelect = {(defaultValue) => setStudentData({ ...studentData, descriptionFinalCondition: defaultValue.children }) }
              optionFilterProp="children"
              //filterOption={false}
              notFoundContent={null}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listFinalConditionsActive
                ? listFinalConditionsActive.map((finalCondition) => {
                    return (
                      <Option key={finalCondition._id}>
                        {finalCondition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>

        {/* evaluo si descriptionFinalCondition es abandonó === true */}
        {handleYearsShow(studentData.descriptionFinalCondition) ? (
          handleYearsShow(studentData.descriptionFinalCondition) === true ? (
            <Col span={12}>
              <label className="control-label">Año de Abandono</label>
              <Form.Item>
                <Select
                  // prefix={<CalendarOutlined/>}
                  //type="date"
                  showSearch
                  placeholder="Año de abandono"
                  //value={inputYearsAbandonment.schoolYear}
                  value={studentData2.schoolYear}
                  //picker="day"
                  onChange={
                    (e) =>
                      //setInputYearsAbandonment({ ...inputYearsAbandonment, schoolYear: e })
                      //setStudentData({ ...studentData, yearsAbandonment:{schoolYear: e}})
                      setYearsAbandonmentData({
                        ...yearsAbandonmentData,
                        yearAbandonment: { schoolYear: e },
                      })
                    //setInputs({ ...inputs, schoolYear: e }))
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listSchoolYearsActive
                    ? listSchoolYearsActive.map((schoolYear) => {
                        return (
                          <Option key={schoolYear._id}>
                            {schoolYear.year}
                          </Option>
                        );
                      })
                    : null}
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-view"
                onClick={viewYears}
                icon={<EyeFilled />}
              >
                Ver años de abandono
              </Button>
            </Col>
          ) : null
        ) : yearsAbandonmentData &&
          yearsAbandonmentData.yearsAbandonment &&
          yearsAbandonmentData.yearsAbandonment.length > 0 ? (
          <Col>
            {" "}
            <Button
              type="primary"
              htmlType="submit"
              className="btn-view"
              onClick={viewYears}
              style={{ marginTop: "25px" }}
              icon={<EyeFilled />}
            >
              Ver años de abandono
            </Button>
          </Col>
        ) : null}

        {/*
        {studentData.descriptionFinalCondition ? (
          studentData.descriptionFinalCondition.toLowerCase() ===
          "recursante" ? (
            <Col span={6}>
              <label className="control-label">Cantidad de horas</label>
              <Form.Item>
                <InputNumber
                  placeholder="Cantidad de horas cursadas anteriormente"
                  name="numberHours"
                  value={studentData.hoursCoursed}
                  onChange={(e) =>
                    setStudentData({ ...studentData, hoursCoursed: e })
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ) : null
        ) : null}
        {studentData.descriptionFinalCondition ? (
          studentData.descriptionFinalCondition.toLowerCase() ===
          "recursante" ? (
            <Col span={6}>
              <label className="control-label">Año Abandono</label>
              <Form.Item>
                <Select
                  // prefix={<CalendarOutlined/>}
                  //type="date"
                  placeholder="Año de abandono"
                  //value={inputYearsAbandonment.schoolYear}
                  value={studentData.schoolYear}
                  //picker="day"
                  //onChange={(e) =>
                    //setInputYearsAbandonment({ ...inputYearsAbandonment, schoolYear: e })
                  //}
                  onChange={(e) => 
                    setStudentData({ ...studentData, yearsAbandonment:{schoolYear: e} })
                    //setInputs({ ...inputs, schoolYear: e })
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listSchoolYearsActive
                  ? listSchoolYearsActive.map((schoolYear) => {
                    return (
                      <Option key={schoolYear._id}>
                        {schoolYear.year}
                      </Option>
                    );
                  })
                  : null}
                </Select>
              </Form.Item>
            </Col>
          ) : null
        ) : null}
      */}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              value={studentData.observation}
              onChange={(e) =>
                setStudentData({ ...studentData, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {advisers.length > 0 ? (
          <Col span={6} step={2}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-view"
              style={{ width: "80%" }}
              size={"small"}
              shape={"round"}
              onClick={viewAdvisers}
              icon={<EyeFilled />}
            >
              Ver Consejeros
            </Button>
          </Col>
        ) : null}
        {finalConditionsData.length > 0 ? (
          <Col span={6}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-view"
              size={"small"}
              shape={"round"}
              style={{ width: "80%" }}
              onClick={viewFinalConditions}
              icon={<EyeFilled />}
            >
              Ver Condiciones Finales
            </Button>
          </Col>
        ) : null}
      </Row>

      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {viewYearsAbandonment ? (
          yearsAbandonmentData.yearsAbandonment ? (
            <Col span={24}>
              <Years
                years={studentData2.yearsAbandonment}
                studentData={studentData}
                setReload={setReload}
                setReloadStudents={setReloadStudents}
              />
            </Col>
          ) : null
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {viewListAdvisers ? (
          advisers ? (
            <Col span={24}>
              <Advisers
                advisers={advisers}
                setAdvisers={setAdvisers}
                adviser={studentData.adviser}
                finalConditions={finalConditionsData}
              />
            </Col>
          ) : null
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {viewListFinalConditions ? (
          finalConditionsData ? (
            <Col span={24}>
              <FinalConditions
                finalConditions={finalConditionsData}
                finalCondition={studentData.finalCondition}
                setFinalConditionsData={setFinalConditionsData}
              />
            </Col>
          ) : null
        ) : null}
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateStudent}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Years(props) {
  const { years, setReload, setReloadStudents, studentData } = props;

  const showDeleteConfirm = (yearA) => {
    const accessToken = getAccessToken();
    //console.log("yearA: ", yearA);
    //console.log("studentData: ", studentData);

    confirm({
      title: "Eliminando -> Año de abandono del estudiante",
      content: `¿Estás seguro que quieres eliminar el año de abandono ${yearA.schoolYear.year} del estudiante?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteYearAbandonmentApi(accessToken, yearA, studentData._id).then(
          (response) => {
            if (response.code === 200) {
              //console.log("se eliminó el año");
              setReload(true);
              setReloadStudents(true);
            } else {
              //console.log("no se pudo eliminar el año");
            }
          }
        );
      },
    });
  };
  const delete_year = (
    <div>
      {" "}
      <p>Eliminar Año de abandono</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Año de abandono",
      dataIndex: "schoolYear",
      sorted: (a, b) => b.schoolYear.year - a.schoolYear.year,
      render: (schoolYear) => (schoolYear ? schoolYear.year : null),
      width: "50%",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, yearAbandonment) => (
        <div>
          <>
            <Popover content={delete_year}>
              <Button
                className="button-inscription"
                type="danger"
                onClick={() => showDeleteConfirm(yearAbandonment)}
              >
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
        </div>
      ),
      //width: "50%",
    },
  ];

  return (
    <Table
      pagination={{ pageSize: 6 }}
      dataSource={years}
      size="small"
      columns={columns}
      rowKey="_id"
    />
  );
}

function Advisers(props) {
  const { advisers, adviser, setAdvisers, finalConditions } = props;

  const [adviserActual, setAdviserActual] = useState(null);
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    let result = advisers.filter((i) => !i.finalDate);
    if (
      result.length > 0 &&
      result[0].adviser &&
      result[0].adviser._id === adviser
    ) {
      setAdviserActual(result[0]._id);
    } else {
      setAdviserActual(null);
    }
  }, [adviser]);

  const finalConditionsAssociated = (adviser) => {
    if (finalConditions.length > 0) {
      let result = finalConditions.filter(
        (i) => i.adviser && i.adviser._id === adviser._id
      );
      if (result.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const showDeleteConfirm = (data) => {
    //Verificar que el consejero no tenga referencias asociadas en las condiciones finales
    if (!finalConditionsAssociated(data.adviser)) {
      confirm({
        title: "Eliminando -> Consejero",
        content: `¿Estás seguro que quieres eliminar el consejero '${data.adviser.name} ${data.adviser.lastname}' del estudiante?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          const newData = advisers.filter((item) => item._id !== data._id);
          setAdvisers(newData);
        },
      });
    } else {
      ModalAntd.info({
        title: "Eliminando -> Consejero",
        content: `No se puede eliminar a '${data.adviser.name} ${data.adviser.lastname}' porque existen condiciones finales asociados al consejero`,
        onOk() {},
      });
    }
  };
  const delete_adviser = (
    <div>
      {" "}
      <p>Eliminar Consejero</p>{" "}
    </div>
  );

  const edit_adviser = (
    <div>
      {" "}
      <p>Editar Fechas Consejeros</p>{" "}
    </div>
  );

  const changeDate = (rowIndex, columnKey) => (event) => {
    const newData = [...advisers];
    newData[rowIndex][columnKey] = formatDateHour(moment(event));
    setAdvisers(newData);
  };

  const columns = [
    {
      title: "Consejero",
      dataIndex: "adviser",
      render: (adviser) =>
        adviser ? adviser.name + " " + adviser.lastname : null,
      width: "30%",
    },
    {
      title: "Fecha Inicio",
      dataIndex: "initDate",
      //render: (initDate) => (initDate ? formatDateView(initDate) : null),
      render: (value, record, rowIndex) =>
        editState && value ? (
          <DatePicker
            // prefix={<CalendarOutlined/>}
            allowClear={false}
            placeholder=""
            format="DD/MM/YYYY"
            //type="date"
            locale={locale}
            picker="date"
            value={moment(value)}
            onChange={changeDate(rowIndex, "initDate")}
            style={{ width: "75%" }}
          />
        ) : value ? (
          formatDateView2(moment(value))
        ) : null,
      width: "25%",
    },
    {
      title: "Fecha Fin",
      dataIndex: "finalDate",
      //render: (finalDate) => (finalDate ? formatDateView(finalDate) : null),
      render: (value, record, rowIndex) =>
        editState && value ? (
          <DatePicker
            // prefix={<CalendarOutlined/>}
            allowClear={false}
            placeholder=""
            format="DD/MM/YYYY"
            //type="date"
            locale={locale}
            picker="date"
            value={moment(value)}
            onChange={changeDate(rowIndex, "finalDate")}
            style={{ width: "75%" }}
          />
        ) : value ? (
          formatDateView2(moment(value))
        ) : null,
      width: "25%",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, adviserData) => (
        <>
          <Space>
            <Popover content={delete_adviser}>
              <Button
                className="button-inscription"
                type="danger"
                size="small"
                disabled={adviserData._id === adviserActual ? true : false}
                onClick={() => showDeleteConfirm(adviserData)}
              >
                <DeleteOutlined />
              </Button>
            </Popover>
          </Space>
        </>
      ),
      //width: "50%",
    },
  ];

  return (
    <>
      <Row>
        <Col span={20} offset={23}>
          <Popover content={edit_adviser}>
            <Button
              className="btn-edit"
              type={editState ? "default" : "primary"}
              // onClick={() => setEditState(!editState)}
              onClick={() => setEditState(!editState)}
              size="medium"
              shape="circle"
              // style={{width:"20px"}}
            >
              <EditOutlined />
            </Button>
          </Popover>
        </Col>
      </Row>
      <Table
        pagination={{ pageSize: 6 }}
        dataSource={advisers}
        size="small"
        columns={columns}
        rowKey="_id"
      />
    </>
  );
}

function FinalConditions(props) {
  const { finalConditions, setFinalConditionsData } = props;

  const [editState, setEditState] = useState(false);

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Eliminando -> Condición Final",
      content: `¿Estás seguro que quieres eliminar la condición final asociada al estudiante?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        const newData = finalConditions.filter((item) => item._id !== data._id);
        setFinalConditionsData(newData);
      },
    });
  };

  const changeDate = (rowIndex, columnKey) => (event) => {
    const newData = [...finalConditions];
    if (event === null) {
      delete newData[rowIndex][columnKey];
    } else {
      newData[rowIndex][columnKey] = formatDateHour(moment(event));
    }
    setFinalConditionsData(newData);
  };

  // Función para eliminar la fecha de fin de una fila
  const eliminarFechaFin = (e, rowIndex, columnKey) => {
    e.preventDefault();
    const newData = [...finalConditions];
    delete newData[rowIndex][columnKey];
    setFinalConditionsData(newData);
  };

  const delete_condition = (
    <div>
      {" "}
      <p>Eliminar Condición Final</p>{" "}
    </div>
  );

  const edit_condition = (
    <div>
      {" "}
      <p>Editar Fechas Condiciones Finales</p>{" "}
    </div>
  );

  const save_condition = (
    <div>
      {" "}
      <p>Guardar Fechas Condiciones Finales</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Condición Final",
      dataIndex: "finalCondition",
      render: (finalCondition) =>
        finalCondition ? finalCondition.description : null,
      width: "20%",
    },
    {
      title: "Consejero",
      dataIndex: "adviser",
      render: (adviser) =>
        adviser ? adviser.name + " " + adviser.lastname : null,
      width: "25%",
    },
    {
      title: "Fecha Inicio",
      dataIndex: "initDate",
      //render: (initDate) => (initDate ? formatDateView(initDate) : null),
      render: (value, record, rowIndex) =>
        editState && value ? (
          <DatePicker
            // prefix={<CalendarOutlined/>}
            allowClear={false}
            placeholder=""
            format="DD/MM/YYYY"
            //type="date"
            locale={locale}
            picker="date"
            value={moment(value)}
            onChange={changeDate(rowIndex, "initDate")}
            style={{ width: "75%" }}
          />
        ) : value ? (
          formatDateView2(moment(value))
        ) : null,
      width: "20%",
      sorter: (a, b) => new Date(a.initDate) - new Date(b.initDate),
    },
    {
      title: "Fecha Final",
      dataIndex: "finalDate",
      //render: (finalDate) => (finalDate ? formatDateView(finalDate) : null),
      render: (value, record, rowIndex) =>
        editState ? (
          <DatePicker
            // prefix={<CalendarOutlined/>}
            allowClear={false}
            placeholder=""
            format="DD/MM/YYYY"
            //type="date"
            locale={locale}
            picker="date"
            value={value ? moment(value) : null}
            onChange={changeDate(rowIndex, "finalDate")}
            style={{ width: "75%" }}
          />
        ) : value ? (
          formatDateView2(moment(value))
        ) : null,
      width: "20%",
      // sorter:(a, b) =>
      //   new Date(a.finalDate) - new Date(b.finalDate)
      // ,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, finalConditionData) => (
        <>
          <Space>
            <Popover content={delete_condition}>
              <Button
                className="button-inscription"
                type="danger"
                size="small"
                onClick={() => showDeleteConfirm(finalConditionData)}
                // style={{width:"20px"}}
              >
                <DeleteOutlined />
              </Button>
            </Popover>
          </Space>
        </>
      ),
      width: "35%",
    },
  ];

  return (
    <>
      <Row>
        <Col span={1} offset={20}>
          {editState ? (
            <Popover content={save_condition}>
              <Button
                type="primary"
                // onClick={() => setEditState(!editState)}
                onClick={() => setEditState(!editState)}
                size="medium"
                shape="circle"
                // style={{width:"20px"}}
              >
                <SaveOutlined />
              </Button>{" "}
            </Popover>
          ) : null}
        </Col>
        <Col span={1}>
          <Popover content={edit_condition}>
            <Button
              type={editState ? "default" : "primary"}
              // onClick={() => setEditState(!editState)}
              onClick={() => setEditState(!editState)}
              size="medium"
              shape="circle"
              // style={{width:"20px"}}
            >
              <EditOutlined />
            </Button>
          </Popover>
        </Col>
      </Row>
      <Table
        pagination={{ pageSize: 6 }}
        dataSource={finalConditions}
        size="small"
        columns={columns}
        rowKey="_id"
      />
    </>
  );
}
